// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advantages-js": () => import("./../../../src/pages/advantages.js" /* webpackChunkName: "component---src-pages-advantages-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-js": () => import("./../../../src/pages/installation.js" /* webpackChunkName: "component---src-pages-installation-js" */),
  "component---src-pages-reasons-js": () => import("./../../../src/pages/reasons.js" /* webpackChunkName: "component---src-pages-reasons-js" */),
  "component---src-pages-traditional-disadvantages-js": () => import("./../../../src/pages/traditional-disadvantages.js" /* webpackChunkName: "component---src-pages-traditional-disadvantages-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-what-is-required-js": () => import("./../../../src/pages/what-is-required.js" /* webpackChunkName: "component---src-pages-what-is-required-js" */)
}

